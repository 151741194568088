import { useMutation, useQueryClient } from '@tanstack/react-query';
import { localstorage } from '#/lib/localStorage';

export const useRemoveLocalStoreValue = (key: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            await queryClient.removeQueries({ queryKey: ['local-store', key], exact: true });
            await localstorage.removeItem(key);
        },
    });
};
