import React, { useCallback, useEffect } from 'react';
import { useLogin } from 'react-admin';
import { useForm } from 'react-hook-form';
import useWaitForIdentity from '#/hooks/useWaitForIdentity';
import { localstorage } from '#/lib/localStorage';
import { Alert, AlertTitle, Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { getAuth, signInWithEmailLink } from 'firebase/auth';

export type EmailSignInCallbackFormValues = {
    email: string;
};

const EmailSignInCallbackForm: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailSignInCallbackFormValues>();
    const auth = getAuth();
    const login = useLogin();
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState('');
    const waitForIdentity = useWaitForIdentity();

    const doSignIn = useCallback(
        async (email: string) => {
            try {
                const result = await signInWithEmailLink(auth, email, window.location.href);
                setLoading(true);
                await login(result.user);
                await waitForIdentity();
                await localstorage.removeItem('emailForSignIn');
                window.location.href = '/';
            } catch (e) {
                setLoading(false);
                setError(e?.message || 'Unknown error');
            }
        },
        [auth, login, waitForIdentity]
    );

    useEffect(() => {
        let isMounted = true;
        localstorage.getItem<string>('emailForSignIn').then((email) => {
            if (!isMounted || !email) {
                return;
            }
            setEmail(email as string);
            setLoading(false);
            return doSignIn(email);
        });

        return () => {
            isMounted = false;
        };
    }, [doSignIn]);

    const onSubmit = async (data: EmailSignInCallbackFormValues) => {
        await doSignIn(data.email);
    };

    const isErrors = Boolean(errors.email);
    const helpText = errors.email?.type === 'required' ? 'This is required' : '';
    if (error) {
        return (
            <Box sx={{ py: 2 }}>
                <Alert severity="error">
                    <AlertTitle>Authentication failure</AlertTitle>
                    <Box sx={{ py: 1 }}>There was an error while trying to authenticate:</Box>
                    <pre>{error}</pre>
                </Alert>
            </Box>
        );
    }
    if (loading || email) {
        return <CircularProgress className="animated fadeIn" sx={{ margin: '20px' }} />;
    }
    return (
        <Stack direction="column" sx={{ pt: 1 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body2"></Typography>
            <Alert severity="warning">
                <AlertTitle>Session expired</AlertTitle>
                <Box sx={{ py: 1 }}>Please renter your email address</Box>
            </Alert>
            <TextField
                label="Email"
                type="email"
                margin="normal"
                required
                fullWidth
                variant="filled"
                {...register('email', { required: true, maxLength: 30 })}
                error={isErrors}
                helperText={helpText}
            />
            <Button type="submit" variant="contained" sx={{ mb: 2 }}>
                Submit
            </Button>
        </Stack>
    );
};

export default EmailSignInCallbackForm;
