import { ResourceCallbacks } from 'react-admin';
import { Review } from '@jucy-askja/common/schemas/Review';

export const reviewTemplateAfterRead: ResourceCallbacks[] = [
    {
        resource: 'reviewTemplate',
        afterRead: async (review: Review) => {
            review.kpis = review.kpis?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0)) || [];
            return review;
        },
    },
];
