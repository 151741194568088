import { KPI } from '../schemas/KPI';

export const getKpiMetaState = (kpi?: KPI) => {
    const isTemplate = kpi?.source === 'template';
    const employeeApproved = Boolean(kpi?.employeeApproved);
    const managerApproved = employeeApproved && kpi?.managerStatus === 'Approved';
    return {
        isTemplate: isTemplate,
        isTitleReadOnly: managerApproved || (!kpi?.editTitle && isTemplate),
        isDescriptionReadOnly: managerApproved || (!kpi?.editDescription && isTemplate),
        isCategoryReadOnly: managerApproved || (!kpi?.editCategory && isTemplate),
    };
};
