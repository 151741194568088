import './lib/init';
import React from 'react';
import { Resource } from 'react-admin';
import { Providers } from './components/Providers';
import { Admin } from './components/layout/Admin';
import * as competencyCategory from './resources/CompetencyCategory';
import * as employeeLevel from './resources/EmployeeLevel';
import * as employeeReview from './resources/EmployeeReview';
import * as employee from './resources/Employees';
import * as hrReview from './resources/HrReview';
import * as jobTitle from './resources/JobTitle';
import * as review from './resources/Review';
import * as reviewPeriod from './resources/ReviewPeriod';
import * as selfReview from './resources/SelfReview';
import * as template from './resources/Templates';

export const App = () => (
    <Providers>
        <Admin>
            <Resource name="selfReview" options={{ label: 'KPI Review' }} list={selfReview.List} edit={selfReview.Edit} show={selfReview.Show} />
            <Resource name="employeeReview" options={{ label: 'Employee Reviews' }} list={employeeReview.List} edit={employeeReview.Edit} />
            <Resource name="hrReview" options={{ label: 'HR Reviews' }} list={hrReview.List} edit={hrReview.Edit} />
            <Resource name="editReview" options={{ label: 'Edit Reviews' }} list={review.List} edit={review.Edit} />
            <Resource name="reviewPeriod" options={{ label: 'Review Periods' }} list={reviewPeriod.List} create={reviewPeriod.Create} edit={reviewPeriod.Edit} />
            <Resource name="employeeJobTitle" options={{ label: 'Job Titles' }} list={jobTitle.List} create={jobTitle.Create} edit={jobTitle.Edit} />
            <Resource
                name="reviewTemplate"
                options={{ label: 'Templates' }}
                recordRepresentation={(record) => record.title || record.id}
                list={template.List}
                create={template.Create}
                edit={template.Edit}
            />
            <Resource
                name="employee"
                options={{ label: 'Employees' }}
                list={employee.List}
                create={employee.Create}
                edit={employee.Edit}
                recordRepresentation={(record) => `${record.name || ''}`}
            />
            <Resource
                name="employeeLevel"
                options={{ label: 'Employee Levels' }}
                list={employeeLevel.List}
                create={employeeLevel.Create}
                edit={employeeLevel.Edit}
                recordRepresentation={(record) => `${record.name || ''}`}
            />
            <Resource
                name="competencyCategory"
                options={{ label: 'Competency Categories' }}
                list={competencyCategory.List}
                create={competencyCategory.Create}
                edit={competencyCategory.Edit}
                recordRepresentation={(record) => `${record.name || ''}`}
            />
        </Admin>
    </Providers>
);
