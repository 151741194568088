import React, { useCallback, useState } from 'react';
import { LoginComponent, LoginProps, useNotify } from 'react-admin';
import { useSearchParams } from 'react-router';
import EmailSignInCallbackForm from '#/components/authentification/EmailSignInCallbackForm';
import EmailSignInForm from '#/components/authentification/EmailSignInForm';
import { useLogin } from '#/hooks/useLogin';
import { config } from '@jucy-askja/common/config';
import { Alert, AlertTitle, Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { OAuthProvider, getAuth, isSignInWithEmailLink, signInWithPopup } from 'firebase/auth';
import Container from './authentification/Container';

const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
    tenant: config.msConfig.tenantId,
});

const CustomLoginPage: React.FC<LoginProps> = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentQueryParameters, setSearchParams] = useSearchParams();
    const mode = currentQueryParameters.get('mode');

    const auth = getAuth();
    const [isSignInWithEmailCallback] = useState(() => isSignInWithEmailLink(auth, window.location.href));
    // const isSignInWithEmailCallback =true

    const showEmailSignInForm = useCallback(() => {
        currentQueryParameters.set('mode', 'email');
        setSearchParams(currentQueryParameters);
    }, [currentQueryParameters, setSearchParams]);

    const hideEmailSignInForm = useCallback(() => {
        currentQueryParameters.delete('mode');
        setSearchParams(currentQueryParameters);
    }, [currentQueryParameters, setSearchParams]);

    const login = useLogin();

    const handleStaffLogin = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, provider);
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;
            const idToken = credential?.idToken;
            if (!accessToken || !idToken) {
                notify('Invalid email or password');
                return;
            }
            await login(result.user);
        } catch (error) {
            setLoading(false);
            notify('Invalid email or password');
            setError(error?.message || 'Unknown error');
        }
    };

    if (error) {
        return (
            <Container>
                <Box paddingBottom={2}>
                    <Alert severity="error">
                        <AlertTitle>Authentication failure</AlertTitle>
                        <Box sx={{ py: 1 }}>There was an error while trying to authenticate:</Box>
                        <pre>{error || 'Unknown error'}</pre>
                    </Alert>
                </Box>
                <Box paddingBottom={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Retry
                    </Button>
                </Box>
            </Container>
        );
    }

    if (loading) {
        return (
            <Container>
                <CircularProgress className="animated fadeIn" sx={{ margin: '20px' }} />
            </Container>
        );
    }

    if (mode === 'email' || isSignInWithEmailCallback) {
        return (
            <Container>
                <Stack direction="column" alignItems="center" gap={0} sx={{ maxWidth: '268px' }}>
                    <Button
                        sx={{ alignSelf: 'self-start', lineHeight: 1, py: 0, ml: -1 }}
                        onClick={() => {
                            if (isSignInWithEmailCallback) {
                                window.location.href = '/';
                            } else {
                                hideEmailSignInForm();
                            }
                        }}
                        variant="text"
                    >
                        &lt; Back
                    </Button>
                    <Typography variant="h6">Sign in with personal email</Typography>
                    {isSignInWithEmailCallback ? <EmailSignInCallbackForm /> : <EmailSignInForm />}
                </Stack>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h6">Welcome!</Typography>
            Log in with
            <Stack sx={{ alignItems: 'center' }}>
                <Button onClick={handleStaffLogin} variant="contained" sx={{ margin: 2, width: '160px' }}>
                    Work email
                </Button>
                <Divider flexItem />
                or
                <Divider flexItem />
                <Button onClick={showEmailSignInForm} variant="contained" sx={{ margin: 2, width: '160px' }}>
                    Personal email
                </Button>
            </Stack>
        </Container>
    );
};

export default CustomLoginPage as LoginComponent;
