import React from 'react';
import { AppBar, AppBarClasses, Link, Logout, LogoutClasses, UserMenu, useAuthProvider } from 'react-admin';
import { useEndImpersonation } from '#/hooks/useEndImpersonation';
import { useGetLocalStoreValue } from '#/hooks/useGetLocalStoreValue';
import { palette } from '#/themes/customTheme';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Box, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';

export const CustomAppBar = () => (
    <AppBar
        sx={{
            [`.${AppBarClasses.menuButton}`]: {
                display: 'none',
            },
            backgroundColor: '#fff',
        }}
        userMenu={<CustomUserMenu />}
    >
        <Typography component={Link} to="/" variant="h3" color={palette.text.primary} sx={{ mr: 'auto', fontSize: '2.5rem', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
            <Box component="img" src={'/R2ELogo.svg'} alt="logo" sx={{ height: '30px', marginRight: 2 }} />
            Road2Excellence
        </Typography>
    </AppBar>
);

const CustomUserMenu = () => {
    const authProvider = useAuthProvider();
    const { mutate: endImpersonate } = useEndImpersonation();
    const { data: originalUserId } = useGetLocalStoreValue('originalUid');
    return (
        <UserMenu>
            {authProvider ? <Logout /> : null}
            {originalUserId ? (
                <MenuItem onClick={() => endImpersonate()}>
                    <ListItemIcon className={LogoutClasses.icon}>
                        <LockResetIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>End impersonation</ListItemText>
                </MenuItem>
            ) : null}
        </UserMenu>
    );
};
