import * as React from 'react';
import { useEffect } from 'react';
import { useGetList } from 'react-admin';
import { useGenerateReviews } from '#/hooks/useGenerateReviews';
import { useGetUserProfile } from '#/hooks/useGetUserProfile';
import { Box, CircularProgress, Typography } from '@mui/material';

export const EmployeeReviewList = React.lazy(() => import('#/resources/EmployeeReview/EmployeeReviewList'));
export const SelfReviewList = React.lazy(() => import('#/resources/SelfReview/SelfReviewList'));

const Dashboard = () => {
    const { data: profile, isLoading: isProfileLoading } = useGetUserProfile();
    const showEmployeeReview = profile?.roles?.includes('manager') || profile?.roles?.includes('admin');
    const {
        isLoading: isReviewLoading,
        data: reviews,
        refetch: refetchReviews,
    } = useGetList('selfReview', { pagination: { page: 1, perPage: 9999 }, filter: { employeeId: profile?.id } }, { enabled: Boolean(profile?.id) });
    const { isLoading: isEmployeeReviewLoading } = useGetList(
        'employeeReview',
        { pagination: { page: 1, perPage: 9999 }, filter: { managerId: profile?.id } },
        { enabled: Boolean(profile?.id) }
    );
    const { mutateAsync: generateReviews } = useGenerateReviews();
    useEffect(() => {
        if (!isReviewLoading && !reviews?.length && profile?.id) {
            generateReviews({ employeeId: profile?.id }).then(() => refetchReviews());
        }
    }, [generateReviews, isReviewLoading, profile?.id, refetchReviews, reviews?.length]);
    if (isEmployeeReviewLoading || isProfileLoading || isReviewLoading) {
        return (
            <Box display="flex" justifyContent="center" padding={2} width="100%">
                <CircularProgress />
            </Box>
        );
    }
    return (
        <>
            <Box
                sx={{
                    marginTop: 6,
                    mx: 'auto',
                    maxWidth: '800px',
                    minWidth: {
                        '@800': '800px',
                        '@': '0',
                    },
                }}
            >
                <Typography variant="h4">Your KPIs</Typography>
                <SelfReviewList disableSyncWithLocation={true} />
                {showEmployeeReview ? (
                    <>
                        <Typography variant="h4">Your Teams KPIs</Typography>
                        <EmployeeReviewList disableSyncWithLocation={true} />
                    </>
                ) : null}
            </Box>
        </>
    );
};

export default Dashboard;
