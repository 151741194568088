import { config } from '@jucy-askja/common/config';
import { LicenseInfo } from '@mui/x-license-pro';
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectFirestoreEmulator, initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

LicenseInfo.setLicenseKey('50a2ce9c677e8555b0805b112d07311dTz0xMDkxNjksRT0xNzczNDQ2Mzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');

export const app = initializeApp({
    apiKey: config.firebaseConfig.apiKey,
    authDomain: config.firebaseConfig.authDomain,
    projectId: config.firebaseConfig.projectId,
    storageBucket: config.firebaseConfig.storageBucket,
    messagingSenderId: config.firebaseConfig.messagingSenderId,
    appId: config.firebaseConfig.appId,
    databaseURL: config.firebaseConfig.databaseURL,
});

const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true });
export const functions = getFunctions(app, 'australia-southeast1');

const emulatorConfig = config.firebaseConfig.emulator;
export const perf = emulatorConfig ? null : getPerformance(app);
if (emulatorConfig?.auth) {
    connectAuthEmulator(getAuth(), `http://${emulatorConfig.auth.host}:${emulatorConfig.auth.port}`, { disableWarnings: true });
}
if (emulatorConfig?.firestore) {
    connectFirestoreEmulator(firestore, emulatorConfig.firestore.host, emulatorConfig.firestore.port);
}
if (emulatorConfig?.functions) {
    connectFunctionsEmulator(functions, emulatorConfig.functions.host, emulatorConfig.functions.port);
}
if (emulatorConfig?.storage) {
    connectStorageEmulator(getStorage(), emulatorConfig.storage.host, emulatorConfig.storage.port);
}
if (emulatorConfig?.database) {
    connectDatabaseEmulator(getDatabase(), emulatorConfig.database.host, emulatorConfig.database.port);
}
