import React, { ReactElement, useMemo } from 'react';
import { Menu, MenuItemLinkProps, useBasename } from 'react-admin';
import { matchPath, useLocation } from 'react-router-dom';
import { useGetRoles } from '#/hooks/useGetRoles';
import { EmployeeRole } from '@jucy-askja/common/schemas/EmployeeRole';
import CategoryIcon from '@mui/icons-material/Category';
import EditIcon from '@mui/icons-material/Edit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import FilterListIcon from '@mui/icons-material/FilterList';
import GradingIcon from '@mui/icons-material/Grading';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import PeopleIcon from '@mui/icons-material/People';
import ReviewsIcon from '@mui/icons-material/Reviews';
import WorkIcon from '@mui/icons-material/Work';
import { Box, ListSubheader } from '@mui/material';

export type MenuItem = {
    to: string;
    activePaths?: string[];
    primaryText: string;
    leftIcon: ReactElement;
    roles?: EmployeeRole[];
};
type MenuSection = {
    title: string;
    items: MenuItem[];
};
const menuItems: Record<string, MenuSection> = {
    primary: {
        title: 'Reviews',
        items: [
            { to: '/', primaryText: 'Your KPIs', leftIcon: <ReviewsIcon />, activePaths: ['selfReview'] },
            { to: '/hrReview', primaryText: 'HR Reviews', leftIcon: <GradingIcon />, roles: ['admin', 'hr'] },
        ],
    },
    admin: {
        title: 'Admin',
        items: [
            { to: '/editReview', primaryText: 'Edit Reviews', leftIcon: <EditIcon />, roles: ['admin'] },
            { to: '/employeeLevel', primaryText: 'Employee Levels', leftIcon: <FilterListIcon />, roles: ['admin'] },
            { to: '/reviewTemplate/UneOsMYLELfj5ymEiZs7', primaryText: 'Core values', leftIcon: <MilitaryTechIcon />, roles: ['admin'] },
            { to: '/competencyCategory', primaryText: 'KPI Categories', leftIcon: <CategoryIcon />, roles: ['admin'] },
            { to: '/reviewPeriod', primaryText: 'Review Periods', leftIcon: <EditCalendarIcon />, roles: ['admin'] },
        ],
    },
    employees: {
        title: 'Employees',
        items: [
            { to: '/employee', primaryText: 'Employees', leftIcon: <PeopleIcon />, roles: ['admin'] },
            { to: '/employeeJobTitle', primaryText: 'Job Title', leftIcon: <WorkIcon />, roles: ['admin'] },
        ],
    },
    manager: {
        title: 'Manager',
        items: [{ to: '/employee', primaryText: 'Employees', leftIcon: <PeopleIcon />, roles: ['manager'] }],
    },
};

const useMenuItems = (userRoles?: EmployeeRole[]) =>
    useMemo(() => {
        const items: MenuSection[] = [];
        for (const key in menuItems) {
            if (menuItems[key].items) {
                const section = {
                    ...menuItems[key],
                    items: menuItems[key].items.filter((item: MenuItem) => {
                        if (item.roles?.includes('manager') && userRoles?.includes('admin')) {
                            return false;
                        }
                        if (item.roles) {
                            return userRoles ? item.roles.some((role) => userRoles?.includes(role)) : false;
                        }
                        return true;
                    }),
                };
                if (section.items.length) {
                    items.push(section);
                }
            }
        }
        return items;
    }, [userRoles]);

export const CustomMenu = () => {
    const roles = useGetRoles();
    const menuItems = useMenuItems(roles);

    return (
        <Box>
            {menuItems.map((section) => (
                <Menu
                    component="div"
                    key={section.title}
                    subheader={
                        <ListSubheader component="div" sx={{ background: 'transparent', lineHeight: 0, pt: 3, pb: 2 }}>
                            {section.title}
                        </ListSubheader>
                    }
                >
                    {section.items.map((item) => (
                        <CustomMenuItem key={`${item.to}${item.primaryText}`} to={item.to} primaryText={item.primaryText} leftIcon={item.leftIcon} menuItem={item} />
                    ))}
                </Menu>
            ))}
        </Box>
    );
};

function decodePath(value: string) {
    try {
        return value
            .split('/')
            .map((v) => decodeURIComponent(v).replace(/\//g, '%2F'))
            .join('/');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_error) {
        return value;
    }
}

const CustomMenuItem = ({ className, menuItem, ...props }: MenuItemLinkProps & { menuItem?: MenuItem }) => {
    const { pathname } = useLocation();
    const basename = useBasename();
    const to = (typeof props.to === 'string' ? props.to : props.to.pathname) || '';
    const active = [to, ...(menuItem?.activePaths || [])]?.some((to) => matchPath({ path: to, end: to === `${basename}/` }, decodePath(pathname)));
    return <Menu.Item selected={active} {...props} />;
};
