import React from 'react';
import { useForm } from 'react-hook-form';
import { localstorage } from '#/lib/localStorage';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, AlertTitle, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

export type EmailSignInCallbackFormValues = {
    email: string;
};

const EmailSignInForm: React.FC = () => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm<EmailSignInCallbackFormValues>();
    const auth = getAuth();

    const onSubmit = async (data: EmailSignInCallbackFormValues) => {
        try {
            await sendSignInLinkToEmail(auth, data.email, {
                url: window.origin,
                handleCodeInApp: true,
            });
            await localstorage.setItem('emailForSignIn', data.email);
        } catch (e) {
            setError('root.serverError', {
                message: e.message,
            });
        }
    };
    return (
        <Stack direction="column" sx={{ pt: 1 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            {isSubmitSuccessful ? (
                <Alert icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success" sx={{ my: 1 }}>
                    <AlertTitle> Check your inbox!</AlertTitle>
                    We&#39;ve sent you an email with a link to sign in. Simply click the link, and you&#39;ll be automatically signed in.
                    <br />
                    <br />
                    Didn’t receive the email? Check your spam or junk folder, or{' '}
                    <Button loading={isSubmitting} type="submit" sx={{ display: 'inline', p: 0 }} variant="text">
                        resend the email
                    </Button>
                </Alert>
            ) : (
                <>
                    <Typography variant="body2">Please enter your email address to sign in:</Typography>
                    <TextField
                        label="Email"
                        type="email"
                        margin="normal"
                        required
                        variant="filled"
                        fullWidth={true}
                        {...register('email', { required: true, maxLength: 30 })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.type === 'required' ? 'Email address is required' : ''}
                    />
                    <Button loading={isSubmitting} type="submit" variant="contained" sx={{ mb: 2 }}>
                        Submit
                    </Button>
                </>
            )}
            {errors.root?.serverError ? (
                <Alert severity="error">
                    <AlertTitle>Authentication failure</AlertTitle>
                    <Box sx={{ py: 1 }}>There was an error while trying to authenticate:</Box>
                    <pre>{errors.root?.serverError?.message || 'Failed to send you a confirmation email'}</pre>
                </Alert>
            ) : null}
        </Stack>
    );
};

export default EmailSignInForm;
