import { ResourceCallbacks } from 'react-admin';
import { autoId } from '@jucy-askja/common/lib/autoId';
import { stripHtml } from '@jucy-askja/common/lib/stripHtml';
import { Template } from '@jucy-askja/common/schemas/Template';

export const reviewTemplateBeforeSave: ResourceCallbacks[] = [
    {
        resource: 'reviewTemplate',
        beforeSave: async (template: Template) => {
            const kpis = template?.kpis || [];
            let count = 0;
            for (const kpi of kpis) {
                kpi.sortOrder = count;
                if (!kpi.id) {
                    kpi.id = autoId();
                }
                const description = stripHtml(kpi.description);
                if (!description) {
                    kpi.description = null;
                }
                count++;
            }
            return template;
        },
    },
];
