import { FirebaseDataProvider } from '#/lib/react-admin-firebase';
import * as ra from '#/lib/react-admin-firebase/misc/react-admin-models';
import { withLifecycleCallbacks } from 'react-admin';
import sortFilterPaginate, { Filter } from '#/lib/arrayUtils';
import { sortTemplatesByLevels } from '#/lib/sortTemplatesByLevels';
import { config } from '@jucy-askja/common/config';
import { Template } from '@jucy-askja/common/schemas/Template';
import { options } from '../providerOptions';
import { reviewAfterRead } from './callbacks/reviewAfterRead';
import { reviewBeforeSave } from './callbacks/reviewBeforeSave';
import { reviewTemplateAfterRead } from './callbacks/reviewTemplateAfterRead';
import { reviewTemplateBeforeSave } from './callbacks/reviewTemplateBeforeSave';

const resourceMapping: { [key: string]: string } = {
    selfReview: 'review',
    employeeReview: 'review',
    hrReview: 'review',
    editReview: 'review',
};
const mapResource = (resource: string) => resourceMapping[resource] || resource;
const defaultDataProvider = FirebaseDataProvider(config.firebaseConfig, options);

export const dataProvider = withLifecycleCallbacks(
    {
        async getList<RecordType extends ra.RaRecord = ra.RaRecord>(resource: string, params: ra.GetListParams) {
            const mappedResource = mapResource(resource);

            if (mappedResource === 'review' && params.filter.employeeLevelId) {
                const { employeeLevelId, ...outFilter } = params.filter;
                const [templatesIds, items] = await Promise.all([
                    defaultDataProvider
                        .getList<Template>('reviewTemplate', {
                            pagination: { page: 1, perPage: 9999 },
                            filter: { employeeLevelId },
                        })
                        .then((res) => res.data.map((template) => template.id)),
                    defaultDataProvider.getList<RecordType>('review', {
                        ...params,
                        pagination: { page: 1, perPage: 9999 },
                        filter: {
                            ...outFilter,
                        },
                    }),
                ]);

                return {
                    ...items,
                    ...sortFilterPaginate<RecordType>(items.data, { filter: { functionalTemplateId: templatesIds } as Filter<RecordType> }),
                };
            }
            const result = await defaultDataProvider.getList<RecordType>(mappedResource, params);

            if (mappedResource === 'reviewTemplate' && params.sort?.field === 'employeeLevelId') {
                const employeeLevels = await defaultDataProvider
                    .getList('employeeLevel', { filter: {}, pagination: { page: 1, perPage: 9999 }, sort: { field: 'sortOrder', order: 'ASC' } })
                    .then((res) => res.data || []);
                return {
                    ...result,
                    data: (await sortTemplatesByLevels({
                        templates: result.data as unknown as Template[],
                        order: params.sort.order,
                        employeeLevels,
                    })) as unknown as RecordType[],
                };
            }

            return result;
        },
        getOne<RecordType extends ra.RaRecord = ra.RaRecord>(resource: string, params: ra.GetOneParams): Promise<ra.GetOneResult<RecordType>> {
            return defaultDataProvider.getOne(mapResource(resource), params);
        },
        getMany<RecordType extends ra.RaRecord = ra.RaRecord>(resource: string, params: ra.GetManyParams): Promise<ra.GetManyResult<RecordType>> {
            return defaultDataProvider.getMany(mapResource(resource), params);
        },
        getManyReference<RecordType extends ra.RaRecord = ra.RaRecord>(resource: string, params: ra.GetManyReferenceParams): Promise<ra.GetManyReferenceResult<RecordType>> {
            return defaultDataProvider.getManyReference(mapResource(resource), params);
        },
        update<RecordType extends ra.RaRecord = ra.RaRecord>(resource: string, params: ra.UpdateParams): Promise<ra.UpdateResult<RecordType>> {
            return defaultDataProvider.update(mapResource(resource), params);
        },
        updateMany(resource: string, params: ra.UpdateManyParams): Promise<ra.UpdateManyResult> {
            return defaultDataProvider.updateMany(mapResource(resource), params);
        },
        create<RecordType extends ra.RaRecord = ra.RaRecord>(resource: string, params: ra.CreateParams): Promise<ra.CreateResult<RecordType>> {
            return defaultDataProvider.create(mapResource(resource), params);
        },
        delete<RecordType extends ra.RaRecord = ra.RaRecord>(resource: string, params: ra.DeleteParams): Promise<ra.DeleteResult<RecordType>> {
            return defaultDataProvider.delete(mapResource(resource), params);
        },
        deleteMany(resource: string, params: ra.DeleteManyParams): Promise<ra.DeleteManyResult> {
            return defaultDataProvider.deleteMany(mapResource(resource), params);
        },
    },
    [...reviewAfterRead, ...reviewBeforeSave, ...reviewTemplateBeforeSave, ...reviewTemplateAfterRead]
);
