import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetIdentity, useNotify } from 'react-admin';
import { useRemoveLocalStoreValue } from '#/hooks/useRemoveLocalStoreValue';
import { getEndImpersonationToken } from '#/services/getEndImpersonationToken';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

export const useEndImpersonation = () => {
    const { refetch } = useGetIdentity();
    const queryClient = useQueryClient();
    const notify = useNotify();
    const { mutate: removeLocalStoreValue } = useRemoveLocalStoreValue('originalUid');
    return useMutation({
        mutationFn: async () => {
            await removeLocalStoreValue();
            const response = await getEndImpersonationToken();
            await signInWithCustomToken(getAuth(), response.token);
            await queryClient.invalidateQueries({
                queryKey: ['user-profile'],
            });
            const { data: user } = await refetch();
            notify(`You are now logged in as ${user?.fullName}`, { severity: 'success' });
        },
        onError: (error) => {
            notify(`Error ending impersonating user. ${error?.message}`, { severity: 'error' });
        },
    });
};
