import { KPI } from '../schemas/KPI';
import { KPITemplate } from '../schemas/KPITemplate';
import { isKpiAutoApproved } from './isKpiAutoApproved';

export const mergeKpis = (reviewKpis: KPI[], coreKpis: KPITemplate[], functionalKpis: KPITemplate[]) => {
    const result: KPI[] = [...reviewKpis];
    for (const templatedKpi of [...functionalKpis, ...coreKpis]) {
        const reviewKpi = result.find((competency) => competency.id === templatedKpi.id);
        const autoApprove = isKpiAutoApproved(templatedKpi);
        if (!reviewKpi) {
            result.push({
                ...templatedKpi,
                ...(autoApprove ? { managerStatus: 'Approved', hrStatus: 'Approved' } : undefined),
            });
            continue;
        }
        if (templatedKpi.allowDelete && reviewKpi.isDeleted) {
            continue;
        }
        reviewKpi.templateId = templatedKpi.templateId;
        reviewKpi.sortOrder = templatedKpi.sortOrder;
        reviewKpi.editTitle = templatedKpi.editTitle;
        reviewKpi.editDescription = templatedKpi.editDescription;
        reviewKpi.editCategory = templatedKpi.editCategory;
        reviewKpi.allowDelete = templatedKpi.allowDelete;
        reviewKpi.type = templatedKpi.type;
        if (!templatedKpi.editTitle && templatedKpi.title) {
            if (templatedKpi.title !== reviewKpi.title) {
                reviewKpi.employeeApproved = false;
            }
            reviewKpi.title = templatedKpi.title;
        }
        if (!templatedKpi.editDescription && templatedKpi.description) {
            if (templatedKpi.description !== reviewKpi.description) {
                reviewKpi.employeeApproved = false;
            }
            reviewKpi.description = templatedKpi.description;
        }
        if (!templatedKpi.editCategory && templatedKpi.competencyCategoryId) {
            if (templatedKpi.competencyCategoryId !== reviewKpi.competencyCategoryId) {
                reviewKpi.employeeApproved = false;
            }
            reviewKpi.competencyCategoryId = templatedKpi.competencyCategoryId;
        }
        if (!reviewKpi.employeeApproved) {
            reviewKpi.managerStatus = null;
            reviewKpi.hrStatus = null;
        } else if (autoApprove && reviewKpi.employeeApproved) {
            reviewKpi.managerStatus = 'Approved';
            reviewKpi.hrStatus = 'Approved';
        }
    }
    const coreIds = new Set(coreKpis.map((c) => c.id));
    const functionalIds = new Set(functionalKpis.map((c) => c.id));
    const sortedCoreKpis = result.filter((c) => coreIds.has(c.id)).sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
    const sortedFunctionalKpis = result.filter((c) => functionalIds.has(c.id)).sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
    const rest = result.filter((c) => !coreIds.has(c.id) && !functionalIds.has(c.id)).sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));

    return [...sortedCoreKpis, ...sortedFunctionalKpis, ...rest];
};
