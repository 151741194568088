import { useQuery } from '@tanstack/react-query';
import { useGetIdentity } from 'react-admin';
import { localstorage } from '#/lib/localStorage';

export const useGetLocalStoreValue = <T>(key: string, defaultValue?: T) => {
    const { data: identity, isLoading: identityLoading } = useGetIdentity();
    return useQuery({
        queryKey: ['local-store', key],
        queryFn: () => localstorage.getItem<T>(key).then((result) => result || defaultValue || null),
        enabled: Boolean(!identityLoading && identity?.id),
        staleTime: Infinity,
        initialData: defaultValue,
    });
};
