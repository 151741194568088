import { ResourceCallbacks, ResourceCallbacksValue } from 'react-admin';
import { getCurrentReviewStatus } from '#/lib/getCurrentReviewStatus';
import { stripHtml } from '@jucy-askja/common/lib/stripHtml';
import { Review } from '@jucy-askja/common/schemas/Review';

const htmlFields = ['description', 'managerComment', 'hrComment'] as const;
const reviewBeforeSaveFn: ResourceCallbacksValue<Review> = async (review: Review) => {
    for (const competency of review.kpis) {
        for (const field of htmlFields) {
            const value = stripHtml(competency[field]);
            if (!value) {
                competency[field] = null;
            }
        }
    }
    review.status = getCurrentReviewStatus(review);
    return review;
};

export const reviewBeforeSave: ResourceCallbacks[] = [
    { resource: 'selfReview', beforeSave: reviewBeforeSaveFn },
    { resource: 'employeeReview', beforeSave: reviewBeforeSaveFn },
    { resource: 'hrReview', beforeSave: reviewBeforeSaveFn },
    { resource: 'review', beforeSave: reviewBeforeSaveFn },
    { resource: 'editReview', beforeSave: reviewBeforeSaveFn },
];
