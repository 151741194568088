import {
    ConfirmClasses,
    ConfirmProps,
    DatagridClasses,
    LayoutClasses,
    MenuItemLinkClasses,
    MenuItemLinkProps,
    NotificationClasses,
    NotificationProps,
    ReferenceFieldClasses,
    ReferenceFieldProps,
    SimpleShowLayoutProps,
} from 'react-admin';
import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme, alpha, createTheme, listItemIconClasses, menuItemClasses, tableSortLabelClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { RichTextInputProps } from 'ra-input-rich-text';
import { LayoutProps } from 'ra-ui-materialui/src/layout/Layout';

export const palette = {
    primary: {
        dark: '#987028',
        main: '#D69A2D',
        shadows: [alpha('#D69A2D', 0.2), alpha('#D69A2D', 0.1), alpha('#D69A2D', 0.05)],
        contrastText: '#fff',
    },
    secondary: {
        main: '#7D9CC0',
        shadows: [alpha('#7D9CC0', 0.2), alpha('#7D9CC0', 0.1), alpha('#7D9CC0', 0.05)],
        contrastText: '#fff',
    },
    tertiary: {
        light: '#9D9174',
        main: '#545655',
    },
    text: { primary: '#39383A', secondary: '#171717' },
    background: {
        default: '#FCFCF5',
    },
    success: {
        light: '#ABDB6B',
        main: '#85C430',
        dark: '#486A1A',
        contrastText: '#FFFFFF',
    },
    error: {
        light: '#FE8E51',
        main: '#FB5A02',
        dark: '#C34602',
        contrastText: '#FFFFFF',
    },
    warning: {
        light: '#EE6791',
        main: '#E61E5C',
        dark: '#B81447',
    },
};
const linkColor = palette.primary.dark;
export const customTheme = createTheme({
    sidebar: {
        width: 200,
        closedWidth: 200,
    },
    typography: {
        fontFamily: "'Poppins', 'Verdana', 'sans-serif'",
    },
    palette: palette,
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    color: palette.tertiary.main,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: linkColor,
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: linkColor,
                    [`&.${tableSortLabelClasses.root}`]: {
                        color: linkColor,
                    },
                },
            },
        },
        RaConfirm: {
            styleOverrides: {
                root: {
                    [`& .${ConfirmClasses.confirmPrimary}`]: {
                        color: palette.primary.contrastText,
                    },
                },
            },
        },
        RaReferenceField: {
            styleOverrides: {
                root: {
                    [`& .${ReferenceFieldClasses.link}`]: {
                        '& > *': {
                            color: linkColor,
                        },
                    },
                },
            },
        },
        RaRichTextInput: {
            styleOverrides: {
                root: {
                    '& .RaRichTextInput-editorContent .ProseMirror': {
                        '&, &:hover, &:focus': {
                            backgroundColor: '#ffffff',
                            minHeight: 150,
                        },
                    },
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    [`&.${MenuItemLinkClasses.active}`]: {
                        borderRadius: '0px 100px 100px 0px',
                        backgroundImage: `linear-gradient(98deg, ${palette.primary.main}, ${palette.primary.main} 99%)`,
                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                        [`&,&.${menuItemClasses.gutters}`]: {
                            fontWeight: 'bold',
                            color: palette.primary.contrastText,
                            [`& .${listItemIconClasses.root}`]: {
                                color: palette.primary.contrastText,
                            },
                        },
                    },
                },
            },
        },
        RaSimpleShowLayout: {
            styleOverrides: {
                root: ({ theme }) => ({
                    paddingTop: (theme as Theme).spacing(2),
                }),
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained' as const,
            },
            styleOverrides: {
                root: {
                    variants: [
                        {
                            props: { variant: 'contained' },
                            style: {
                                borderRadius: 12,
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                            },
                        },
                    ],
                },
                sizeSmall: ({ theme }) => ({
                    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
                }),
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'outlined' as const,
                margin: 'dense' as const,
                size: 'small' as const,
                fullWidth: true,
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.secondary.main,
                }),
            },
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    [`& .${LayoutClasses.content}`]: {
                        containerType: 'size',
                    },
                },
            },
        },
        RaNotification: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontWeight: 500,
                    [`& .${NotificationClasses.undo}`]: {
                        color: theme.palette.common.white,
                    },
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    [`&.${DatagridClasses.headerCell}`]: {
                        color: palette.primary.main,
                    },
                },
            },
        },
        RaBulkDeleteWithUndoButton: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.error.main,
                    color: palette.error.contrastText,
                    '&:hover': {
                        backgroundColor: palette.error.dark,
                    },
                },
            },
        },
    },
});

declare module '@mui/material/styles' {
    interface Components {
        RaReferenceField?: {
            defaultProps?: ReferenceFieldProps;
            styleOverrides?: Partial<OverridesStyleRules<keyof typeof ReferenceFieldClasses>>;
        };
        RaRichTextInput?: {
            defaultProps?: RichTextInputProps;
            styleOverrides?: Partial<OverridesStyleRules>;
        };

        RaMenuItemLink?: {
            defaultProps?: MenuItemLinkProps;
            styleOverrides?: Partial<OverridesStyleRules>;
        };
        RaSimpleShowLayout?: {
            defaultProps?: SimpleShowLayoutProps;
            styleOverrides?: Partial<OverridesStyleRules>;
        };
        RaButton?: {
            defaultProps?: ComponentsProps['MuiButton'];
            styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
            variants?: ComponentsVariants<Theme>['MuiButton'];
        };
        RaConfirm?: {
            defaultProps?: ConfirmProps;
            styleOverrides?: Partial<OverridesStyleRules<keyof typeof ConfirmClasses | 'root', 'RaConfirm', Theme>>;
        };
        RaNotification?: {
            defaultProps?: NotificationProps;
            styleOverrides?: Partial<OverridesStyleRules<keyof typeof NotificationClasses | 'root', 'RaNotification', Theme>>;
        };
        RaLayout?: {
            defaultProps?: LayoutProps;
            styleOverrides?: Partial<OverridesStyleRules<keyof typeof LayoutClasses | 'root', 'RaLayout', Theme>>;
        };
        RaBulkDeleteWithUndoButton?: {
            defaultProps?: ComponentsProps['MuiButton'];
            styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
            variants?: ComponentsVariants<Theme>['MuiButton'];
        };
    }

    interface ThemeOptions {
        sidebar?: {
            width?: number;
            closedWidth?: number;
        };
    }

    interface Theme {
        sidebar?: {
            width?: number;
            closedWidth?: number;
        };
    }
}
