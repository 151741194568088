import { useCallback } from 'react';
import { UserIdentity, useGetIdentity } from 'react-admin';

const useWaitForIdentity = <ErrorType extends Error = Error>() => {
    const { refetch } = useGetIdentity<ErrorType>();

    return useCallback(
        async () =>
            new Promise<UserIdentity>((resolve, reject) => {
                const doRefetch = async () => {
                    if (!refetch) {
                        reject();
                        return;
                    }
                    const { data } = await refetch();
                    if (data) {
                        resolve(data);
                        return;
                    }
                    setTimeout(doRefetch, 100);
                };
                doRefetch();
            }),
        [refetch]
    );
};

export default useWaitForIdentity;
