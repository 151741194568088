import { isKpiAutoApproved } from '@jucy-askja/common/lib/isKpiAutoApproved';
import { isKpiIgnored } from '@jucy-askja/common/lib/isKpiIgnored';
import { stripHtml } from '@jucy-askja/common/lib/stripHtml';
import { KPI } from '@jucy-askja/common/schemas/KPI';
import { Review } from '@jucy-askja/common/schemas/Review';
import { ReviewStatus } from '@jucy-askja/common/schemas/ReviewStatus';

export const getCurrentReviewStatus = (review: Review): ReviewStatus => {
    const employeeKpis = review.kpis.filter((kpi) => !isKpiIgnored(kpi));
    const employeeComplete = employeeKpis.every((kpi: KPI) => {
        if (!kpi.employeeApproved) {
            return false;
        }
        const hasTitle = Boolean(kpi?.editTitle ? kpi?.title?.trim() : true);
        if (!hasTitle) {
            return false;
        }
        const hasDescription = Boolean(kpi?.editDescription ? stripHtml(kpi?.description) : true);
        if (!hasDescription) {
            return false;
        }
        const hasCategory = Boolean(kpi?.editCategory ? kpi?.competencyCategoryId?.trim() : true);
        if (!hasCategory) {
            return false;
        }

        if (kpi.managerStatus === 'Request Changes') {
            return false;
        }
        return true;
    });
    if (!employeeComplete) {
        return 'Pending Employee';
    }
    const eligibleKpis = employeeKpis.filter((kpi) => !isKpiAutoApproved(kpi));
    const managerStatus = eligibleKpis.every((kpi: KPI) => kpi.managerStatus === 'Approved');
    if (!managerStatus) {
        return 'Pending Manager';
    }
    const hrStatus = eligibleKpis.every((kpi: KPI) => kpi.hrStatus === 'Approved');
    if (!hrStatus) {
        return 'Pending HR';
    }
    return 'Completed';
};
