import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { removeDoubleSlashes, useAuthProvider, useBasename, useNotificationContext } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import useWaitForIdentity from '#/hooks/useWaitForIdentity';

const defaultAuthParams = {
    loginUrl: '/login',
    afterLoginUrl: '/',
};
export const useLogin = (): Login => {
    const authProvider = useAuthProvider();
    const queryClient = useQueryClient();
    const location = useLocation();
    const locationState = location.state;
    const navigate = useNavigate();
    const basename = useBasename();
    const { resetNotifications } = useNotificationContext();
    const nextPathName = locationState && locationState.nextPathname;
    const nextSearch = locationState && locationState.nextSearch;
    const afterLoginUrl = removeDoubleSlashes(`${basename}/${defaultAuthParams.afterLoginUrl}`);
    const waitForIdentity = useWaitForIdentity();

    const login = useCallback(
        async (params: unknown = {}, pathName?: string) => {
            if (authProvider) {
                const ret = await authProvider.login(params);
                await waitForIdentity();
                resetNotifications();
                await queryClient.invalidateQueries({
                    queryKey: ['auth', 'getPermissions'],
                });
                if (ret && Object.prototype.hasOwnProperty.call(ret, 'redirectTo')) {
                    if (ret) {
                        navigate(ret.redirectTo);
                    }
                } else {
                    const redirectUrl = pathName ? pathName : nextPathName + nextSearch || afterLoginUrl;
                    navigate(redirectUrl);
                }
                return ret;
            } else {
                resetNotifications();
                navigate(afterLoginUrl);
                return Promise.resolve();
            }
        },
        [authProvider, waitForIdentity, resetNotifications, queryClient, navigate, nextPathName, nextSearch, afterLoginUrl]
    );

    return login;
};

/**
 * Log a user in by calling the authProvider.login() method
 *
 * @param {Object} params Login parameters to pass to the authProvider. May contain username/email, password, etc
 * @param {string} pathName The path to redirect to after login. By default, redirects to the home page, or to the last page visited after disconnection.
 *
 * @return {Promise} The authProvider response
 */
type Login = (params: unknown, pathName?: string) => Promise<unknown>;
