import React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import { ProfileLoader } from '#/components/ProfileLoader';
import { CustomAppBar } from '#/components/layout/CustomAppBar';
import { CustomMenu } from '#/components/layout/CustomMenu';

export const CustomLayout = ({ children, ...props }: LayoutProps) => (
    <Layout {...props} menu={CustomMenu} appBar={CustomAppBar}>
        <ProfileLoader>{children}</ProfileLoader>
    </Layout>
);
