import { functions } from '#/lib/init';
import { Employee } from '@jucy-askja/common/schemas/Employee';
import { httpsCallable } from 'firebase/functions';

const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));
export const getUserProfile = async () => {
    await sleep(4000);
    const response = await httpsCallable<unknown, Employee>(functions, 'getProfile')();
    return response.data;
};
