import React from 'react';
import { AccessDenied, Admin as RaAdmin } from 'react-admin';
import { AccessDeniedClasses, AccessDeniedProps } from 'react-admin';
import { CustomLayout } from '#/components/layout/CustomLayout';
import { queryClient } from '#/lib/QueryClient';
import { authProvider, dataProvider } from '#/services/ra-providers';
import { customTheme } from '#/themes/customTheme';
import LockIcon from '@mui/icons-material/Lock';
import CustomLoginPage from '../CustomLoginPage';
import Dashboard from '../Dashboard';

export const CustomAccessDenied = (props: AccessDeniedProps) => (
    <AccessDenied
        {...props}
        icon={
            <>
                <LockIcon className={AccessDeniedClasses.icon} />
            </>
        }
    />
);

export const Admin = ({ children }: { children: React.ReactNode }) => (
    <RaAdmin
        dashboard={Dashboard}
        queryClient={queryClient}
        loginPage={CustomLoginPage}
        theme={customTheme}
        layout={CustomLayout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        requireAuth={true}
        accessDenied={CustomAccessDenied}
    >
        {children}
    </RaAdmin>
);
