import { KPI } from '../schemas/KPI';
import { getKpiMetaState } from './getKpiMetaState';
import { stripHtml } from './stripHtml';

export const isKpiIgnored = (kpi: KPI) => {
    const { isTitleReadOnly, isDescriptionReadOnly, isCategoryReadOnly } = getKpiMetaState(kpi);

    if (kpi.allowDelete && kpi.isDeleted) {
        return true;
    }

    if (isTitleReadOnly && !kpi?.title?.trim()) {
        return true;
    }

    if (isDescriptionReadOnly && !stripHtml(kpi?.description?.trim())) {
        return true;
    }
    if (isCategoryReadOnly && !kpi?.competencyCategoryId?.trim()) {
        return true;
    }
    return false;
};
