import { ResourceCallbacks, ResourceCallbacksValue } from 'react-admin';
import { mergeKpis } from '@jucy-askja/common/lib/mergeKpis';
import { Review } from '@jucy-askja/common/schemas/Review';
import { Template } from '@jucy-askja/common/schemas/Template';

const reviewAfterReadFn: ResourceCallbacksValue<Review> = async (review: Review, dataProvider) => {
    const [coreTemplateResult, functionalTemplateResult] = await Promise.allSettled([
        dataProvider.getOne<Template>('reviewTemplate', {
            id: review.coreTemplateId,
        }),
        dataProvider.getOne<Template>('reviewTemplate', {
            id: review.functionalTemplateId,
        }),
    ]);

    const coreTemplate = coreTemplateResult.status === 'fulfilled' ? coreTemplateResult.value.data : undefined;
    const functionalTemplate = functionalTemplateResult.status === 'fulfilled' ? functionalTemplateResult.value.data : undefined;

    const coreKpisFromTemplate = (coreTemplate?.kpis || []).map((kpi) => ({
        ...kpi,
        templateId: coreTemplate?.id,
        type: 'Core' as const,
    }));
    const functionalKpisFromTemplate = (functionalTemplate?.kpis || []).map((kpi) => ({
        ...kpi,
        templateId: functionalTemplate?.id,
        type: 'Functional' as const,
    }));
    review.kpis = mergeKpis(review.kpis || [], coreKpisFromTemplate, functionalKpisFromTemplate);
    return review;
};

export const reviewAfterRead: ResourceCallbacks[] = [
    { resource: 'selfReview', afterRead: reviewAfterReadFn },
    { resource: 'employeeReview', afterRead: reviewAfterReadFn },
    { resource: 'hrReview', afterRead: reviewAfterReadFn },
    { resource: 'review', afterRead: reviewAfterReadFn },
    { resource: 'editReview', afterRead: reviewAfterReadFn },
];
