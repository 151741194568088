export type Hierarchical = { id: string; parentId?: string | null };
export type HierarchicalItem<T> = T & Hierarchical;
export type NestedHierarchicalItem<T> = T & HierarchicalItem<T> & { children?: NestedHierarchicalItem<T>[] };
export type WithDepthAndSortOrder<T> = T & { depth: number; sortOrder: number };
export class HierarchicalItems<T extends Hierarchical> {
    public items: NestedHierarchicalItem<T>[];
    public nestedItems: NestedHierarchicalItem<T>[];

    constructor(flatArray: T[]) {
        this.items = flatArray;
        this.nestedItems = this.mapNestedItems(flatArray);
    }

    private mapNestedItems(flatArray: T[]): (T & { children?: T[] })[] {
        const map = new Map<string, T & { children?: T[] }>();
        for (const item of flatArray) {
            map.set(item.id, { ...item, children: [] });
        }
        const nestedArray: (T & { children?: T[] })[] = [];
        for (const item of flatArray) {
            const child = map.get(item.id);
            if (!child) {
                continue;
            }
            const parent = map.get(item.parentId || 'root');
            if (parent && parent.id !== item.id) {
                parent.children!.push(child);
            } else {
                nestedArray.push(child);
            }
        }
        return nestedArray;
    }

    public findPathToItem(targetId: string, field: keyof T = 'id'): string[] {
        const findPath = (items: (T & { children?: T[] })[], path: string[] = []): string[] | null => {
            for (const item of items) {
                const newPath = [...path, item[field] as string];
                if (item.id === targetId) {
                    return newPath;
                }
                if (item.children) {
                    const result = findPath(item.children, newPath);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        };
        return findPath(this.nestedItems) || [];
    }

    itemsWithDepth = (): WithDepthAndSortOrder<T>[] => {
        let order = 0;
        const flattenWithDepth = (nestedArray: NestedHierarchicalItem<T>[], level = 0): WithDepthAndSortOrder<T>[] =>
            nestedArray.reduce((acc, item) => {
                acc.push({ ...item, depth: level, sortOrder: order });
                order++;
                if (item.children) {
                    acc.push(...flattenWithDepth(item.children, level + 1));
                }
                return acc;
            }, [] as WithDepthAndSortOrder<T>[]);
        return flattenWithDepth(this.nestedItems);
    };

    itemsWithIndent = (field: keyof T, indentChar = ' '): T[] => {
        const flattenWithIndent = (nestedArray: NestedHierarchicalItem<T>[], level = 0): T[] =>
            nestedArray.reduce((acc, item) => {
                acc.push({ ...item, [field]: indentChar.repeat(level * 2) + item[field] });
                if (item.children) {
                    acc.push(...flattenWithIndent(item.children, level + 1));
                }
                return acc;
            }, [] as T[]);
        return flattenWithIndent(this.nestedItems);
    };
}
