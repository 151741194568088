import { HierarchicalItems } from '#/lib/HierarchicalItems';
import { Template } from '@jucy-askja/common/schemas/Template';
import { EmployeeLevel } from '@jucy-askja/common/schemas/employeeLevel';

export const sortTemplatesByLevels = async ({
    templates,
    order = 'ASC',
    employeeLevels,
}: {
    templates: Template[];
    order?: 'ASC' | 'DESC';
    employeeLevels: EmployeeLevel[];
}): Promise<Template[]> => {
    const levelData = new HierarchicalItems<EmployeeLevel>(employeeLevels).itemsWithDepth();
    return templates.sort((a, b) => {
        const levelA = levelData.find((level) => level.id === a.employeeLevelId);
        const levelB = levelData.find((level) => level.id === b.employeeLevelId);
        if (!levelA || !levelB) {
            return 0;
        }
        return order === 'ASC' ? levelA.sortOrder - levelB.sortOrder : levelB.sortOrder - levelA.sortOrder;
    });
};
