import { FirebaseAuthProvider } from '#/lib/react-admin-firebase';
import { ReactAdminFirebaseAuthProvider } from '#/lib/react-admin-firebase/providers/AuthProvider';
import { QueryFunctionContext, UserIdentity } from 'react-admin';
import { queryClient } from '#/lib/QueryClient';
import { generateReviews } from '#/services/generateReviews';
import { getUserProfile } from '#/services/getUserProfile';
import { config } from '@jucy-askja/common/config';
import { Employee } from '@jucy-askja/common/schemas/Employee';
import { EmployeeRole } from '@jucy-askja/common/schemas/EmployeeRole';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Unsubscribe, getDatabase, onValue, ref } from 'firebase/database';
import { options } from '../providerOptions';

const defaultAuth = FirebaseAuthProvider(config.firebaseConfig, options);
const db = getDatabase(options.app);

let userClaimsUnsubscribe: Unsubscribe | null = null;
onAuthStateChanged(getAuth(), (user) => {
    if (userClaimsUnsubscribe) {
        userClaimsUnsubscribe();
        userClaimsUnsubscribe = null;
    }
    if (user) {
        const metadataRef = ref(db, `metadata/${user.uid}/refreshTime`);
        userClaimsUnsubscribe = onValue(metadataRef, async () => {
            await user.getIdToken(true);
            await queryClient.invalidateQueries({
                queryKey: ['user-profile', user.uid],
                refetchType: 'all',
            });
        });
    }
});

export type R2EUserIdentity = UserIdentity & { profile: Employee };

interface CustomAuhProvider extends ReactAdminFirebaseAuthProvider {
    getIdentity: (params?: QueryFunctionContext) => Promise<R2EUserIdentity>;
    getPermissions: () => Promise<string[]>;
}

const authDebugEnabled = false;
const authDebugLogger = {
    log: (...args: Parameters<typeof console.log>) => {
        if (authDebugEnabled) {
            // eslint-disable-next-line no-console
            console.log('authProvider', ...args);
        }
    },
    warn: (...args: Parameters<typeof console.warn>) => {
        if (authDebugEnabled) {
            console.warn('authProvider', ...args);
        }
    },
};

export const authProvider: CustomAuhProvider = {
    ...defaultAuth,
    getIdentity: async (params) => {
        const user = await defaultAuth.getIdentity?.(params);
        if (!user) {
            throw new Error('No user found');
        }
        const profile = user.id
            ? await queryClient.fetchQuery({
                  queryKey: ['user-profile', user.id],
                  queryFn: () => getUserProfile(),
                  staleTime: Infinity,
              })
            : undefined;
        const result: R2EUserIdentity = {
            ...user,
            profile: profile as Employee,
        };
        return result;
    },
    logout: async (params) => {
        await queryClient.invalidateQueries({ queryKey: ['user-profile'] });

        return defaultAuth.logout(params);
    },
    login: async (params) => {
        const userData = await defaultAuth.login(params);
        const employee = await queryClient.fetchQuery({
            queryKey: ['user-profile', userData?.uid],
            queryFn: () => getUserProfile(),
            staleTime: Infinity,
        });
        if (employee) {
            await generateReviews({ employeeId: employee?.id });
        }
        return userData;
    },
    async getPermissions() {
        const identity = await authProvider.getIdentity();
        return identity?.profile?.roles || [];
    },
    canAccess: async (params) => {
        authDebugLogger.log('canAccess', params);
        const identity = await authProvider.getIdentity();
        const recordAccessEvaluator = accessMapping[params.resource]?.[params.action as ActionType];
        if (identity.profile.roles.includes('admin')) {
            authDebugLogger.log('User has admin allow', params);
            return true;
        }
        const paramsWithIdentity = { ...params, identity };

        if (typeof recordAccessEvaluator === 'function') {
            const result = recordAccessEvaluator(paramsWithIdentity);
            authDebugLogger.log(`function evaluator returned ${result}`, paramsWithIdentity);
            return result;
        }
        if (typeof recordAccessEvaluator === 'boolean') {
            const result = recordAccessEvaluator;
            authDebugLogger.log(`boolean evaluator returned ${result}`, paramsWithIdentity);
            return result;
        }
        if (Array.isArray(recordAccessEvaluator)) {
            const result = recordAccessEvaluator.some((role) => identity.profile.roles.includes(role));
            authDebugLogger.log(`array evaluator returned ${result}`, paramsWithIdentity);
            return result;
        }
        authDebugLogger.warn('no access evaluator found for', paramsWithIdentity);

        return false;
    },
};

export type ActionType = 'list' | 'create' | 'edit' | 'show';
export type AccessEvaluatorFnParams<RecordType extends Record<string, unknown> = Record<string, unknown>> = {
    action: string;
    resource: string;
    record?: RecordType;
    identity: R2EUserIdentity;
};
export type AccessEvaluatorFn<RecordType extends Record<string, unknown> = Record<string, unknown>> = (params: AccessEvaluatorFnParams<RecordType>) => boolean;
export type AccessEvaluator<RecordType extends Record<string, unknown> = Record<string, unknown>> = EmployeeRole[] | boolean | AccessEvaluatorFn<RecordType>;
export type RecordAccessEvaluator<RecordType extends Record<string, unknown> = Record<string, unknown>> = {
    list?: AccessEvaluator<RecordType>;
    create?: AccessEvaluator<RecordType>;
    edit?: AccessEvaluator<RecordType>;
    show?: AccessEvaluator<RecordType>;
    delete?: AccessEvaluator<RecordType>;
};

const accessMapping: Record<string, RecordAccessEvaluator> = {
    selfReview: {
        list: true,
        edit: ({ identity, record }) => {
            if (!record) {
                return true;
            }
            if (record.status === 'Pending Manager' && record.employeeId === identity.profile.id) {
                return false;
            }

            if (record.status === 'Pending HR' && !identity.profile.roles.includes('hr')) {
                return false;
            }
            return [record.employeeId, record.managerId].includes(identity.profile.id);
        },
        show: ({ identity, record }) => !record || [record.employeeId, record.managerId].includes(identity.profile.id),
        delete: false,
    },
    employeeReview: {
        list: true,
        edit: ({ identity, record }) => {
            if (!record) {
                return true;
            }
            if (record.status === 'Pending Manager' && record.employeeId === identity.profile.id) {
                return false;
            }

            if (record.status === 'Pending HR' && !identity.profile.roles.includes('hr')) {
                return false;
            }
            return [record.employeeId, record.managerId].includes(identity.profile.id);
        },
        show: ({ identity, record }) => !record || [record.employeeId, record.managerId].includes(identity.profile.id),
    },
    hrReview: {
        list: ['hr'],
        create: ['hr'],
        edit: ['hr'],
        show: ['hr'],
        delete: ['hr'],
    },
    editReview: {},
    reviewPeriod: {},
    employeeJobTitle: {},
    reviewTemplate: {},
    employee: {
        list: ['manager'],
        edit: ({ identity, record }) => !record || [record?.id, record?.managerId].includes(identity.profile.id),
        show: ({ identity, record }) => !record || [record?.id, record?.managerId].includes(identity.profile.id),
    },
    employeeLevel: {},
    competencyCategory: {},
};
